import React from 'react';

const LinkedInIcon = () => (
  <svg id="LinkedIn_Layer_1" viewBox="0 0 21 21">
    <path
      className="fill white"
      d="M14.25,17.81a2.52,2.52,0,1,1,3.56-3.56,2.52,2.52,0,1,1-3.56,3.56ZM13.83,34.5v-14h4.36v14Zm16.31,0V27.66a7.51,7.51,0,0,0-.28-2.44,2,2,0,0,0-2-1.27,2.2,2.2,0,0,0-2.11,1.13,5.26,5.26,0,0,0-.46,2.48V34.5H21v-14h4.17v1.93h.05a3.72,3.72,0,0,1,1.5-1.55,4.64,4.64,0,0,1,2.63-.75q3,0,4.26,1.92a9.43,9.43,0,0,1,.94,4.78V34.5Z"
      transform="translate(-13.5 -13.5)"
    />
  </svg>
);

export default LinkedInIcon;
