/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */
import React from 'react';
import PropTypes from 'prop-types';

import Header from './header';
import Footer from './footer';
import ScrollHandler from './scrollHandler';

const Layout = ({ children, location }) => {
  if (location.pathname.indexOf('fnol') !== -1) {
    return <>{children}</>;
  }

  return (
    <ScrollHandler containerId="page" headerId="mainHeader" location={location}>
      <div
        className={
          process.env.GATSBY_CAT_EVENT
            ? `App cat-${process.env.GATSBY_CAT_EVENT}`
            : 'App'
        }
      >
        <Header location={location} />
        <div id="page" className="hfeed site">
          <main id="main" className="site-main" role="main">
            {children}
            <Footer location={location} />
          </main>
        </div>
      </div>
    </ScrollHandler>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired
};

export default Layout;
