import { ROUTES } from './navigation';
import jaySingletary from '../images/jay-singletary.jpg';
import jenniferPeeples from '../images/Jennifer-Peeples.jpg';
import jakeHolehouse from '../images/Jake-Holehouse.jpg';
import floridaBest from '../images/Florida-Best.jpg';
import whitneyRicci from '../images/Whitney-Ricci.jpg';
import greggCohen from '../images/Gregg-Cohen.jpg';
import jeremiahPerez from '../images/Jeremiah-Perez.jpg';

export const CAROUSEL_DATA = {
  [ROUTES.home.path]: [
    {
      image: jaySingletary,
      paragraph: `I’ve been a TypTap policyholder for quite some time now. What I remember most about signing up was how incredibly easy it was! I’ve saved money each year, and the coverage is very strong if anything were to happen.`,
      byLine: `Jay Singletary`,
      cite: `TypTap Policyholder | Tampa, FL`
    },
    {
      image: jenniferPeeples,
      paragraph: `Love offering TypTap Insurance to our clients. The rates are fantastic. We have helped many of our clients save so much money by switching to TypTap.`,
      byLine: `Jennifer Peeples`,
      cite: `Peeples Insurance Agency | Sarasota, FL`
    },
    {
      image: jakeHolehouse,
      paragraph: `I have been a TypTap/HCI Group agent for over 10 years. If you want the best customer service experience, fair claims settlement and financially stable insurance company, you want to choose TypTap. My team at HH Insurance loves writing business with TypTap as we know our clients are well protected in the event of a loss. As an insurance agent, I personally choose to carry some of my policies with HCI Group as I know I have the right coverage with them.`,
      byLine: `Jake Holehouse`,
      cite: `HH Insurance Group | St. Petersburg, FL`
    },
    {
      image: floridaBest,
      paragraph: `We proudly use TypTap to provide our clients with outstanding homeowners coverage. As agency owners, they are a great asset, and we couldn’t be happier with their service.`,
      byLine: `John & Jeanette Lawrenson`,
      cite: `Florida Best Quote Agency | Largo, FL`
    },
    {
      image: whitneyRicci,
      paragraph: `My team loves TypTap’s easy quoting platform! We’ve also been pleased with the claims service provided by TypTap.`,
      byLine: `Whitney Ricci`,
      cite: `Ricci Insurance Group | Jacksonville, FL`
    },
    {
      image: greggCohen,
      paragraph: `I've had great success with the TypTap products. I own a portfolio of single family homes and find the rates to always be competitive and quick to bind.`,
      byLine: `Gregg Cohen`,
      cite: `TypTap Policyholder | Jacksonville, FL`
    },
    {
      image: jeremiahPerez,
      paragraph: `TypTap provides my insureds with broader form homeowners coverage compared to the industry average. TypTap’s technology allows my agency to offer a home insurance quote in minutes and better assist our customers. I’m excited to see TypTap expand with homeowners’ insurance in multiple states and continue enhancing the technology that will support and improve the insured’s experience, agency efficiency, and the overall outlook of our industry.`,
      byLine: `Jeremiah Perez`,
      cite: `Absolute Choice Insurance | Miami, FL`
    }
  ],
  [ROUTES.claims.path]: [
    {
      image: jaySingletary,
      paragraph: `I’ve been a TypTap policyholder for quite some time now. What I remember most about signing up was how incredibly easy it was! I’ve saved money each year, and the coverage is very strong if anything were to happen.`,
      byLine: `Jay Singletary`,
      cite: `TypTap Policyholder | Tampa, FL`
    },
    {
      image: jenniferPeeples,
      paragraph:
        'Love offering TypTap Insurance to our clients. The rates are fantastic. We have helped many of our clients save so much money by switching to TypTap.',
      byLine: 'Jennifer Peeples',
      cite: 'Peeples Insurance Agency | Sarasota, FL'
    },
    {
      image: jakeHolehouse,
      paragraph:
        'I have been a TypTap/HCI Group agent for over 10 years. If you want the best customer service experience, fair claims settlement and financially stable insurance company, you want to choose TypTap. My team at HH Insurance loves writing business with TypTap as we know our clients are well protected in the event of a loss. As an insurance agent, I personally choose to carry some of my policies with HCI Group as I know I have the right coverage with them.',
      byLine: 'Jake Holehouse',
      cite: 'HH Insurance Group | St. Petersburg, FL'
    },
    {
      image: floridaBest,
      paragraph: `We proudly use TypTap to provide our clients with outstanding homeowners coverage. As agency owners, they are a great asset, and we couldn’t be happier with their service.`,
      byLine: `John & Jeanette Lawrenson`,
      cite: `Florida Best Quote Agency | Largo, FL`
    },
    {
      image: whitneyRicci,
      paragraph: `My team loves TypTap’s easy quoting platform! We’ve also been pleased with the claims service provided by TypTap.`,
      byLine: `Whitney Ricci`,
      cite: `Ricci Insurance Group | Jacksonville, FL`
    },
    {
      image: greggCohen,
      paragraph: `I've had great success with the TypTap products. I own a portfolio of single family homes and find the rates to always be competitive and quick to bind.`,
      byLine: `Gregg Cohen`,
      cite: `TypTap Policyholder | Jacksonville, FL`
    },
    {
      image: jeremiahPerez,
      paragraph: `TypTap provides my insureds with broader form homeowners coverage compared to the industry average. TypTap’s technology allows my agency to offer a home insurance quote in minutes and better assist our customers. I’m excited to see TypTap expand with homeowners’ insurance in multiple states and continue enhancing the technology that will support and improve the insured’s experience, agency efficiency, and the overall outlook of our industry.`,
      byLine: `Jeremiah Perez`,
      cite: `Absolute Choice Insurance | Miami, FL`
    }
  ],
  [ROUTES.products.path]: [
    {
      image: jaySingletary,
      paragraph: `I’ve been a TypTap policyholder for quite some time now. What I remember most about signing up was how incredibly easy it was! I’ve saved money each year, and the coverage is very strong if anything were to happen.`,
      byLine: `Jay Singletary`,
      cite: `TypTap Policyholder | Tampa, FL`
    },
    {
      image: jenniferPeeples,
      paragraph: `Love offering TypTap Insurance to our clients. The rates are fantastic. We have helped many of our clients save so much money by switching to TypTap.`,
      byLine: `Jennifer Peeples`,
      cite: `Peeples Insurance Agency | Sarasota, FL`
    },
    {
      image: jakeHolehouse,
      paragraph: `I have been a TypTap/HCI Group agent for over 10 years. If you want the best customer service experience, fair claims settlement and financially stable insurance company, you want to choose TypTap. My team at HH Insurance loves writing business with TypTap as we know our clients are well protected in the event of a loss. As an insurance agent, I personally choose to carry some of my policies with HCI Group as I know I have the right coverage with them.`,
      byLine: `Jake Holehouse`,
      cite: `HH Insurance Group | St. Petersburg, FL`
    },
    {
      image: floridaBest,
      paragraph: `We proudly use TypTap to provide our clients with outstanding homeowners coverage. As agency owners, they are a great asset, and we couldn’t be happier with their service.`,
      byLine: `John & Jeanette Lawrenson`,
      cite: `Florida Best Quote Agency | Largo, FL`
    },
    {
      image: whitneyRicci,
      paragraph: `My team loves TypTap’s easy quoting platform! We’ve also been pleased with the claims service provided by TypTap.`,
      byLine: `Whitney Ricci`,
      cite: `Ricci Insurance Group | Jacksonville, FL`
    },
    {
      image: greggCohen,
      paragraph: `I've had great success with the TypTap products. I own a portfolio of single family homes and find the rates to always be competitive and quick to bind.`,
      byLine: `Gregg Cohen`,
      cite: `TypTap Policyholder | Jacksonville, FL`
    },
    {
      image: jeremiahPerez,
      paragraph: `TypTap provides my insureds with broader form homeowners coverage compared to the industry average. TypTap’s technology allows my agency to offer a home insurance quote in minutes and better assist our customers. I’m excited to see TypTap expand with homeowners’ insurance in multiple states and continue enhancing the technology that will support and improve the insured’s experience, agency efficiency, and the overall outlook of our industry.`,
      byLine: `Jeremiah Perez`,
      cite: `Absolute Choice Insurance | Miami, FL`
    }
  ],
  [ROUTES.agencyToolkit.path]: [
    {
      image: jenniferPeeples,
      paragraph: `Love offering TypTap Insurance to our clients. The rates are fantastic. We have helped many of our clients save so much money by switching to TypTap.`,
      byLine: `Jennifer Peeples`,
      cite: `Peeples Insurance Agency | Sarasota, FL`
    },
    {
      image: jakeHolehouse,
      paragraph: `I have been a TypTap/HCI Group agent for over 10 years. If you want the best customer service experience, fair claims settlement and financially stable insurance company, you want to choose TypTap. My team at HH Insurance loves writing business with TypTap as we know our clients are well protected in the event of a loss. As an insurance agent, I personally choose to carry some of my policies with HCI Group as I know I have the right coverage with them.`,
      byLine: `Jake Holehouse`,
      cite: `HH Insurance Group | St. Petersburg, FL`
    },
    {
      image: floridaBest,
      paragraph: `We proudly use TypTap to provide our clients with outstanding homeowners coverage. As agency owners, they are a great asset, and we couldn’t be happier with their service.`,
      byLine: `John & Jeanette Lawrenson`,
      cite: `Florida Best Quote Agency | Largo, FL`
    },
    {
      image: whitneyRicci,
      paragraph: `My team loves TypTap’s easy quoting platform! We’ve also been pleased with the claims service provided by TypTap.`,
      byLine: `Whitney Ricci`,
      cite: `Ricci Insurance Group | Jacksonville, FL`
    },
    {
      image: jeremiahPerez,
      paragraph: `TypTap provides my insureds with broader form homeowners coverage compared to the industry average. TypTap’s technology allows my agency to offer a home insurance quote in minutes and better assist our customers. I’m excited to see TypTap expand with homeowners’ insurance in multiple states and continue enhancing the technology that will support and improve the insured’s experience, agency efficiency, and the overall outlook of our industry.`,
      byLine: `Jeremiah Perez`,
      cite: `Absolute Choice Insurance | Miami, FL`
    }
  ]
};
