import React, { useEffect, useState } from 'react';
import { Link } from 'gatsby';

import { ROUTES } from '../constants/navigation';
import TypTapLogo from '../assets/svgs/TypTapLogo';

const Header = ({ location }) => {
  const [showMobileMenu, setMobileMenu] = useState(false);

  useEffect(() => {
    if (showMobileMenu) {
      setMobileMenu(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  return (
    <div className={`header-wrapper ${showMobileMenu ? 'nav-active' : ''}`}>
      <header id="mainHeader" role="banner">
        <div className="logo" title="TypTap Insurance">
          <Link to={ROUTES.home.path} title="TypTap Insurance">
            <TypTapLogo />
          </Link>
        </div>
        <div
          className="btn-menu"
          role="button"
          tabIndex="0"
          onClick={() => setMobileMenu(state => !state)}
          onKeyPress={e => e.charCode === 13 && setMobileMenu(state => !state)}
        >
          <span className="arrow-right" />
        </div>
        <nav id="mainnav" className="mainnav" title="mainnav">
          <div className="menu-header-menu-container">
            <ul id="menu-header-menu" className="menu">
              <li className="menu-item">
                <Link
                  exact="true"
                  to={ROUTES.blog.path}
                  activeClassName="selected"
                  title="About Us"
                >
                  About Us
                </Link>
                <ul className="sub-menu about-us-sub-menu">
                  <li className="menu-item">
                    <Link
                      to={`${ROUTES.blog.path}#mission`}
                      title="Our Mission"
                    >
                      Our Mission
                    </Link>
                  </li>

                  <li className="menu-item">
                    <Link to={`${ROUTES.blog.path}#newsroom`} title="Newsroom">
                      Newsroom
                    </Link>
                  </li>
                  <li className="menu-item">
                    <Link
                      to={`${ROUTES.blog.path}#blog`}
                      title="Behind the Scenes"
                    >
                      Behind the Scenes
                    </Link>
                  </li>
                  <li className="menu-item">
                    <Link to={ROUTES.careers.path} title="Careers">
                      Careers
                    </Link>
                  </li>
                </ul>
              </li>
              <li className="menu-item menu-item-has-children">
                <Link
                  to={ROUTES.products.path}
                  activeClassName="selected"
                  title="Products"
                >
                  Products
                </Link>
                <ul className="sub-menu">
                  <li className="menu-item">
                    <Link
                      to={`${ROUTES.products.path}#home`}
                      title="Homeowners"
                    >
                      Homeowners
                    </Link>
                  </li>
                </ul>
              </li>

              <li className="menu-item">
                <Link
                  to={ROUTES.claims.path}
                  title="Claims"
                  activeClassName="selected"
                >
                  Claims
                </Link>
              </li>

              <li className="menu-item">
                <Link
                  to={ROUTES.contact.path}
                  title="Contact"
                  activeClassName="selected"
                >
                  Contact
                </Link>
              </li>

              <li className="menu-item agents">
                <span>Agents</span>
                <ul className="sub-menu">
                  <li className="menu-item">
                    <a
                      href={`${process.env.GATSBY_HARMONY_AGENCY_URL}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      className=""
                    >
                      Agent Log In
                    </a>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </nav>
      </header>
      <div
        className="nav-modal"
        role="button"
        tabIndex="0"
        onKeyPress={e => e.charCode === 13 && setMobileMenu(state => !state)}
        onClick={() => setMobileMenu(state => !state)}
      >
        <div className="times">&#x2716;</div>
      </div>
    </div>
  );
};

export default Header;
