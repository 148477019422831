import React from 'react';

const InstagramIcon = () => (
  <svg id="Instagram_Layer_1" viewBox="0 0 21 21">
    <path
      className="fill white"
      d="M34.45,19.64c0,.88,0,2.33,0,4.36s0,3.49-.07,4.38A8.87,8.87,0,0,1,34,30.7,5.49,5.49,0,0,1,30.7,34a8.87,8.87,0,0,1-2.32.4c-.89,0-2.35.07-4.38.07s-3.49,0-4.38-.07A7.71,7.71,0,0,1,17.3,34a5,5,0,0,1-2-1.24,5.45,5.45,0,0,1-1.29-2,8.87,8.87,0,0,1-.4-2.32c-.05-.89-.07-2.35-.07-4.38s0-3.49.07-4.38A8.87,8.87,0,0,1,14,17.3,5.49,5.49,0,0,1,17.3,14a8.87,8.87,0,0,1,2.32-.4c.89-.05,2.35-.07,4.38-.07s3.49,0,4.38.07a8.87,8.87,0,0,1,2.32.4A5.49,5.49,0,0,1,34,17.3,9.25,9.25,0,0,1,34.45,19.64ZM32.2,30.19a9.63,9.63,0,0,0,.38-2.53c0-.69,0-1.66,0-2.91v-1.5c0-1.28,0-2.25,0-2.91a9.2,9.2,0,0,0-.38-2.53,3.33,3.33,0,0,0-2-2,9.2,9.2,0,0,0-2.53-.38c-.69,0-1.66,0-2.91,0h-1.5c-1.25,0-2.22,0-2.91,0a9.63,9.63,0,0,0-2.53.38,3.33,3.33,0,0,0-2,2,9.2,9.2,0,0,0-.38,2.53c0,.69,0,1.66,0,2.91v1.5c0,1.25,0,2.22,0,2.91a9.63,9.63,0,0,0,.38,2.53,3.52,3.52,0,0,0,2,2,9.63,9.63,0,0,0,2.53.38c.69,0,1.66,0,2.91,0h1.5c1.28,0,2.25,0,2.91,0a9.2,9.2,0,0,0,2.53-.38A3.52,3.52,0,0,0,32.2,30.19ZM24,18.61a5.21,5.21,0,0,1,2.7.73,5.42,5.42,0,0,1,2,2,5.36,5.36,0,0,1,0,5.4,5.42,5.42,0,0,1-2,2,5.36,5.36,0,0,1-5.4,0,5.42,5.42,0,0,1-2-2,5.36,5.36,0,0,1,0-5.4,5.42,5.42,0,0,1,2-2A5.21,5.21,0,0,1,24,18.61Zm0,8.91a3.51,3.51,0,0,0,2.48-6,3.51,3.51,0,1,0-5,5A3.36,3.36,0,0,0,24,27.52Zm6.89-9.14a1.27,1.27,0,1,0-1.27,1.26,1.13,1.13,0,0,0,.87-.37A1.43,1.43,0,0,0,30.89,18.38Z"
      transform="translate(-13.5 -13.5)"
    />
  </svg>
);

export default InstagramIcon;
