import React from 'react';

const YouTubeIcon = () => (
  <svg id="YouTube_Layer_1" viewBox="0 0 25.59 18">
    <path
      className="fill white"
      d="M36.28,17.81a20.86,20.86,0,0,1,.47,3.85l0,2.34,0,2.34a20.91,20.91,0,0,1-.47,3.89,3.34,3.34,0,0,1-.84,1.41,3.16,3.16,0,0,1-1.46.84,32.59,32.59,0,0,1-5.71.47L24,33l-4.27,0A32.59,32.59,0,0,1,14,32.48a3.16,3.16,0,0,1-1.46-.84,3.34,3.34,0,0,1-.84-1.41,20.91,20.91,0,0,1-.47-3.89L11.2,24c0-.69,0-1.47.05-2.34a20.86,20.86,0,0,1,.47-3.85,3.36,3.36,0,0,1,.84-1.45A3.16,3.16,0,0,1,14,15.52a32.59,32.59,0,0,1,5.71-.47L24,15l4.27.05a32.59,32.59,0,0,1,5.71.47,3.16,3.16,0,0,1,1.46.84A3.36,3.36,0,0,1,36.28,17.81Zm-14.9,10L28.08,24l-6.7-3.8Z"
      transform="translate(-11.2 -15)"
    />
  </svg>
);

export default YouTubeIcon;
