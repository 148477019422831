import React from 'react';

const FaxIcon = () => (
  <svg id="Fax_Layer_1" viewBox="0 0 42 42">
    <path
      className="fill white"
      d="M8.33,13a2.57,2.57,0,0,1,1.88.79A2.55,2.55,0,0,1,11,15.67V42.33A2.7,2.7,0,0,1,8.33,45H5.67a2.55,2.55,0,0,1-1.88-.79A2.57,2.57,0,0,1,3,42.33V15.67a2.55,2.55,0,0,1,.79-1.88A2.55,2.55,0,0,1,5.67,13ZM42,16.39a2.82,2.82,0,0,1,2.22.78A2.53,2.53,0,0,1,45,19V42.38a2.5,2.5,0,0,1-.79,1.84,2.6,2.6,0,0,1-1.88.78H15.67a2.58,2.58,0,0,1-1.88-.78A2.5,2.5,0,0,1,13,42.38V5.62a2.5,2.5,0,0,1,.79-1.84A2.58,2.58,0,0,1,15.67,3H36.06a7,7,0,0,1,1.8,1l3.78,3.78A1.42,1.42,0,0,1,42,9.58ZM37,19V14H33.19a5,5,0,0,1-.95-.6.91.91,0,0,1-.24-1V8H19V19ZM26,27.75v-2.5A1.2,1.2,0,0,0,24.75,24h-2.5a1.24,1.24,0,0,0-.9.35,1.25,1.25,0,0,0-.35.9v2.5A1.2,1.2,0,0,0,22.25,29h2.5a1.25,1.25,0,0,0,.9-.35A1.24,1.24,0,0,0,26,27.75Zm0,11v-2.5A1.2,1.2,0,0,0,24.75,35h-2.5a1.24,1.24,0,0,0-.9.35,1.25,1.25,0,0,0-.35.9v2.5a1.25,1.25,0,0,0,.35.9,1.24,1.24,0,0,0,.9.35h2.5A1.2,1.2,0,0,0,26,38.75Zm11-11v-2.5A1.2,1.2,0,0,0,35.75,24h-2.5A1.2,1.2,0,0,0,32,25.25v2.5a1.24,1.24,0,0,0,.35.9,1.25,1.25,0,0,0,.9.35h2.5a1.25,1.25,0,0,0,.9-.35A1.24,1.24,0,0,0,37,27.75Zm0,11v-2.5A1.2,1.2,0,0,0,35.75,35h-2.5A1.2,1.2,0,0,0,32,36.25v2.5A1.2,1.2,0,0,0,33.25,40h2.5A1.2,1.2,0,0,0,37,38.75Z"
      transform="translate(-3 -3)"
    />
  </svg>
);

export default FaxIcon;
