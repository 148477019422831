import React from 'react';
import { Carousel } from 'react-responsive-carousel';

import { CAROUSEL_DATA } from '../constants/carousel';
import { ROUTES } from '../constants/navigation';

const CarouselFooter = ({ location }) => {
  const carouselData = location.pathname.includes('toolkit')
    ? CAROUSEL_DATA[ROUTES.agencyToolkit.path]
    : CAROUSEL_DATA[location.pathname];
  if (!carouselData) return null;

  return (
    <Carousel
      showThumbs={false}
      centerMode
      showStatus={false}
      centerSlidePercentage={100}
    >
      {carouselData &&
        carouselData.map((slide, i) => (
          <div className="carousel-slide" key={i}>
            <img src={slide.image} alt={slide.image} />
            <blockquote>
              <p>{slide.paragraph}</p>
              <footer>
                {slide.byLine}
                <cite>{slide.cite}</cite>
              </footer>
            </blockquote>
          </div>
        ))}
    </Carousel>
  );
};

export default CarouselFooter;
