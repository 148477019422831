/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
const CustomLayout = require('./wrapPageElement').default;

require('react-responsive-carousel/lib/styles/carousel.min.css');
require('./src/assets/index.scss');

exports.wrapPageElement = CustomLayout;
