import React from 'react';

const TelephoneIcon = () => (
  <svg id="Phone_Layer_1" viewBox="0 0 42 42">
    <path
      className="fill white"
      d="M43.77,33A1.8,1.8,0,0,1,45,34.77a1,1,0,0,1-.08.41l-2,8.53a2,2,0,0,1-.37.78,2,2,0,0,1-.7.57,1.75,1.75,0,0,1-.82.21,36.64,36.64,0,0,1-26.9-11.16A36.67,36.67,0,0,1,3,7.2a1.82,1.82,0,0,1,.21-.82,1.89,1.89,0,0,1,.57-.69,1.81,1.81,0,0,1,.78-.37l8.53-2a.86.86,0,0,1,.41-.08A1.79,1.79,0,0,1,15.3,4.5l3.94,9.18a1.64,1.64,0,0,1,.17.74A2,2,0,0,1,18.67,16l-4.92,4a29.3,29.3,0,0,0,6.15,8.37,29.69,29.69,0,0,0,8.37,6.15l4-4.92a2,2,0,0,1,1.55-.74,1.75,1.75,0,0,1,.74.16Z"
      transform="translate(-3 -3.27)"
    />
  </svg>
);

export default TelephoneIcon;
