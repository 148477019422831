import React from 'react';

const FacebookIcon = () => (
  <svg id="Facebook_Layer_1" viewBox="0 0 12.38 24">
    <path
      className="fill white"
      d="M21.42,25.27H17.81V21h3.61V17.63a5.54,5.54,0,0,1,1.5-4.15,5.42,5.42,0,0,1,4-1.48,22.51,22.51,0,0,1,3.28.19V16H27.94a2.17,2.17,0,0,0-1.74.57,2.4,2.4,0,0,0-.37,1.5V21h4l-.56,4.27H25.83V36H21.42Z"
      transform="translate(-17.81 -12)"
    />
  </svg>
);

export default FacebookIcon;
