import React from 'react';

const TypTapLogo = ({ className }) => (
  <svg
    id="TypTap_Insurance"
    data-name="TypTap_Insurance"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 1317.13 417.3"
  >
    <defs>
      <linearGradient
        id="gray-gradient"
        x1="122.73"
        y1="209.11"
        x2="199.2"
        y2="285.58"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0%" stopColor="#767676" />
        <stop offset="27%" stopColor="#686868" />
        <stop offset="100%" stopColor="#565757" />
      </linearGradient>
      <linearGradient
        id="shadow-gradient-1"
        x1="397.14"
        y1="-194.63"
        x2="395.38"
        y2="-98.54"
        gradientTransform="translate(-206.65 223.59) scale(1.05 0.66)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="40%" stopColor="#3d3e3e" />
        <stop offset="40%" stopColor="#3e3f3f" stopOpacity="0.97" />
        <stop offset="47%" stopColor="#545555" stopOpacity="0.27" />
        <stop offset="50%" stopColor="#5d5e5e" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="shadow-gradient-2"
        x1="681.55"
        y1="92.31"
        x2="682.07"
        y2="173.57"
        gradientTransform="translate(-505.19 89.43) scale(1.05 0.89)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="42%" stopColor="#545555" />
        <stop offset="43%" stopColor="#595a5a" stopOpacity="0.57" />
        <stop offset="44%" stopColor="#5d5e5d" stopOpacity="0.26" />
        <stop offset="46%" stopColor="#5f605f" stopOpacity="0.07" />
        <stop offset="47%" stopColor="#606160" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="blue-gradient"
        x1="114.55"
        y1="28.17"
        x2="206.55"
        y2="120.17"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0%" stopColor="#00b2e9" />
        <stop offset="26%" stopColor="#289cd7" />
        <stop offset="57%" stopColor="#2591c8" />
        <stop offset="100%" stopColor="#207dab" />
      </linearGradient>
      <linearGradient
        id="shadow-gradient-3"
        x1="796.52"
        y1="44"
        x2="798.75"
        y2="125.22"
        gradientTransform="translate(-715.02 65.52) scale(1.05 0.66)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="37%" stopColor="#166287" />
        <stop offset="39%" stopColor="#17658b" stopOpacity="0.91" />
        <stop offset="42%" stopColor="#1a6e96" stopOpacity="0.68" />
        <stop offset="47%" stopColor="#207ba9" stopOpacity="0.31" />
        <stop offset="50%" stopColor="#2487b8" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="green-gradient"
        x1="43.87"
        y1="130.25"
        x2="277.46"
        y2="363.84"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0%" stopColor="#6abf4b" />
        <stop offset="34%" stopColor="#79bf43" />
        <stop offset="100%" stopColor="#5c9645" />
      </linearGradient>
    </defs>
    <path
      style={{ fill: 'url(#gray-gradient)' }}
      d="M66.61,412.13,5.17,350.69a17.63,17.63,0,0,1,0-24.94L251.84,79.08,325.75,153a17.63,17.63,0,0,1,0,24.94L91.55,412.13A17.63,17.63,0,0,1,66.61,412.13Z"
      transform="translate(0 0)"
    />
    <rect
      style={{ fill: 'url(#shadow-gradient-1)' }}
      x="150.13"
      y="121.22"
      width="122.34"
      height="7.64"
      transform="translate(-26.53 186.03) rotate(-45)"
    />
    <rect
      style={{ fill: 'url(#shadow-gradient-2)' }}
      x="151.24"
      y="199.69"
      width="122.08"
      height="10.33"
      transform="translate(217.53 499.82) rotate(-135)"
    />
    <path
      style={{ fill: 'url(#blue-gradient)' }}
      d="M165.46,165.46,79.08,79.08,153,5.17a17.63,17.63,0,0,1,24.94,0l73.91,73.91Z"
      transform="translate(0 0)"
    />
    <rect
      style={{ fill: 'url(#shadow-gradient-3)' }}
      x="63.88"
      y="115.63"
      width="122.18"
      height="7.64"
      transform="translate(128.88 292.28) rotate(-135)"
    />
    <path
      style={{ fill: 'url(#green-gradient)' }}
      d="M239.37,412.13,5.17,177.93a17.63,17.63,0,0,1,0-24.94L79.08,79.08,325.75,325.75a17.63,17.63,0,0,1,0,24.94l-61.44,61.44A17.63,17.63,0,0,1,239.37,412.13Z"
      transform="translate(0 0)"
    />
    <path
      className="gray"
      d="M392.13,281.28V269.47h48.66v11.81l-15.17,2.61V390.6l15.17,2.61v11.72H392.13V393.21l15.16-2.61V283.89Z"
      transform="translate(0 0)"
    />
    <path
      className="gray"
      d="M452.79,393.21,468,390.6V318.68l-15.17-2.6V304.26h31.54l1.3,15a36.59,36.59,0,0,1,12.61-12.42,33.69,33.69,0,0,1,17.26-4.42q16.28,0,25.21,9.54t8.93,29.44V390.6l15.17,2.61v11.72H516.15V393.21l15.16-2.61V341.76q0-13.31-5.25-18.89t-16-5.58a25.85,25.85,0,0,0-14,3.81,27.9,27.9,0,0,0-9.73,10.42V390.6l15.17,2.61v11.72H452.79Z"
      transform="translate(0 0)"
    />
    <path
      className="gray"
      d="M545.43,9.86V52.52H523.17l-2-22.12h-42.8V191.16l21.6,3.71v16.7h-69.3v-16.7l21.61-3.71V30.4H409.35l-1.86,22.12H385.1V9.86Z"
      transform="translate(0 0)"
    />
    <path
      className="gray"
      d="M688.78,85l-12.85,2L621.34,233.56a73.81,73.81,0,0,1-15.63,25.17q-10.22,10.74-27.43,10.74a57.25,57.25,0,0,1-7.94-.73c-3.28-.49-5.79-.95-7.56-1.39l2.65-20.54a34.57,34.57,0,0,1,4.7.26q5.49.41,7,.4,8.34,0,13.51-7.22a80.86,80.86,0,0,0,9-16.63l6.23-14.84L558.54,87.15,545.43,85V68.2h57.89V85l-16.16,2.65,24.77,70.75,4.77,18.29h.8l29.81-89L631,85V68.2h57.77Z"
      transform="translate(0 0)"
    />
    <path
      className="gray"
      d="M703.35,249.86l21.6-3.71V88.74L703.35,85V68.2h44l2.39,17.49a48.32,48.32,0,0,1,17.42-15q10.27-5.16,23.79-5.17,26.63,0,41.4,21.14t14.77,55.84v2.79q0,31-14.84,50t-40.94,19q-13.25,0-23.25-4.44a44.9,44.9,0,0,1-17-13.18v49.42l21.6,3.71v16.82h-69.3ZM820.88,142.53q0-24.5-9.54-40.14T783,86.75q-11.13,0-19.08,5a38,38,0,0,0-12.86,13.78v69.82a36.14,36.14,0,0,0,12.79,13.71q7.88,4.85,19.41,4.84,18.56,0,28.09-13.51t9.54-35.11Z"
      transform="translate(0 0)"
    />
    <path
      className="gray"
      d="M999.73,9.86V52.52H977.47l-2-22.12h-42.8V191.16l21.61,3.71v16.7H885v-16.7l21.61-3.71V30.4H863.65l-1.86,22.12H839.4V9.86Z"
      transform="translate(0 0)"
    />
    <path
      className="gray"
      d="M1088.84,211.57q-1.2-5.83-1.86-10.47t-.93-9.28a63.13,63.13,0,0,1-18.87,16.1,48.23,48.23,0,0,1-24.19,6.43q-22.4,0-34-10.94t-11.66-30.8q0-20.4,16.5-31.47T1059,130.08h26.89V113.39q0-12.58-8-20t-22.12-7.35a54.11,54.11,0,0,0-17.29,2.52,32.45,32.45,0,0,0-12.13,6.89l-2.78,15.1h-20.14V82.78a77.24,77.24,0,0,1,23.72-12.52,93.61,93.61,0,0,1,30.21-4.71q24.36,0,39.48,12.46t15.1,35.64v69c0,1.86,0,3.67.07,5.43s.16,3.54.33,5.3l10.6,1.46v16.7Zm-42-19.08a46.37,46.37,0,0,0,23.71-6.23q10.74-6.22,15.37-15V147.7H1058.1q-16,0-25.37,7.42t-9.35,18q0,9.42,5.83,14.37T1046.84,192.49Z"
      transform="translate(0 0)"
    />
    <path
      className="gray"
      d="M1141.56,249.86l21.61-3.71V88.74L1141.56,85V68.2h44l2.4,17.49a48.37,48.37,0,0,1,17.41-15q10.28-5.16,23.79-5.17,26.63,0,41.4,21.14t14.78,55.84v2.79q0,31-14.84,50t-41,19q-13.24,0-23.24-4.44a45,45,0,0,1-17-13.18v49.42l21.61,3.71v16.82h-69.31Zm117.53-107.33q0-24.5-9.54-40.14T1221.2,86.75q-11.13,0-19.08,5a38,38,0,0,0-12.86,13.78v69.82a36.24,36.24,0,0,0,12.8,13.71q7.86,4.85,19.4,4.84,18.55,0,28.09-13.51t9.54-35.11Z"
      transform="translate(0 0)"
    />
    <path
      className="gray"
      d="M1261.86,45.45a28.72,28.72,0,0,1,8.06-20.67,27,27,0,0,1,39.13,0,28.69,28.69,0,0,1,8.08,20.67A28.87,28.87,0,0,1,1309,66.2a26.86,26.86,0,0,1-39.12,0A28.89,28.89,0,0,1,1261.86,45.45Zm4.75,0a24.15,24.15,0,0,0,6.67,17.3,22.17,22.17,0,0,0,32.39,0,24.16,24.16,0,0,0,6.68-17.3,24,24,0,0,0-6.68-17.22,22.28,22.28,0,0,0-32.41,0A24,24,0,0,0,1266.61,45.45Zm17.28,3V61.53H1278V28.44H1289q5.88,0,9.26,2.65a9,9,0,0,1,3.39,7.55,7,7,0,0,1-1.13,4,9.12,9.12,0,0,1-3.31,2.9,7.23,7.23,0,0,1,3.29,3.29,11.27,11.27,0,0,1,1,5V56a27.15,27.15,0,0,0,.13,2.86,6.64,6.64,0,0,0,.52,2.08v.63h-6a7.32,7.32,0,0,1-.43-2.4c0-1.05-.07-2.12-.07-3.21v-2.1a5.64,5.64,0,0,0-1.31-4.09,5.81,5.81,0,0,0-4.26-1.32Zm0-5.22h5.29a8.9,8.9,0,0,0,4.68-1.16,3.73,3.73,0,0,0,1.9-3.35c0-1.87-.5-3.18-1.51-3.93s-2.79-1.13-5.3-1.13h-5.06Z"
      transform="translate(0 0)"
    />
    <path
      className="gray"
      d="M653.78,335.9H640l-3-12.1a24.83,24.83,0,0,0-8.42-5.07,32.49,32.49,0,0,0-11.49-1.91q-9.76,0-14.52,4.28a13.44,13.44,0,0,0-4.74,10.33,11.5,11.5,0,0,0,4.19,9.4q4.19,3.44,17,6.14,19.08,4.09,28.38,11.12a22.4,22.4,0,0,1,9.3,18.84q0,13.4-10.84,21.67t-28.33,8.28A73.53,73.53,0,0,1,597,404.23a54.78,54.78,0,0,1-16.28-7.67l-.09-23h13.77l3.44,13.49a19.09,19.09,0,0,0,8.61,4.18,47.42,47.42,0,0,0,11.11,1.31q9.78,0,15.31-4a12.17,12.17,0,0,0,5.53-10.37,12.5,12.5,0,0,0-4.6-10q-4.61-3.9-17.45-6.79-18.33-4-27.63-10.88a22,22,0,0,1-9.3-18.61q0-12.28,10.46-20.94t27.22-8.65a65.92,65.92,0,0,1,20.56,3,42.65,42.65,0,0,1,15.16,8.28Z"
      transform="translate(0 0)"
    />
    <path
      className="gray"
      d="M745.89,390a32.64,32.64,0,0,1-29.49,16.84q-16.38,0-25.59-10.51t-9.21-32.75V318.68l-11.53-2.6V304.26h29.86v59.55q0,16.1,4.75,22.14T719.47,392q9.76,0,16.14-4a24.68,24.68,0,0,0,9.63-11.21V318.68l-13.49-2.6V304.26h31.82V390.6l11.54,2.61v11.72h-28Z"
      transform="translate(0 0)"
    />
    <path
      className="gray"
      d="M789.62,316.08V304.26h31.54l1.77,14.61a35.14,35.14,0,0,1,10.56-12.1,24.54,24.54,0,0,1,14.37-4.37,30.82,30.82,0,0,1,4.42.33,24.49,24.49,0,0,1,3.49.7l-2.42,17-10.42-.56a21,21,0,0,0-12.19,3.4,21.92,21.92,0,0,0-7.63,9.54V390.6l15.17,2.61v11.72H789.62V393.21l15.16-2.61V318.68Z"
      transform="translate(0 0)"
    />
    <path
      className="gray"
      d="M934.11,404.93q-.84-4.1-1.31-7.35c-.31-2.17-.52-4.34-.65-6.51a44.19,44.19,0,0,1-13.26,11.3,33.83,33.83,0,0,1-17,4.51q-15.72,0-23.91-7.67t-8.18-21.63q0-14.32,11.58-22.1t31.77-7.77h18.89V336q0-8.83-5.58-14t-15.54-5.17a38,38,0,0,0-12.14,1.77,22.72,22.72,0,0,0-8.51,4.84l-2,10.6H874.19V314.5a54.57,54.57,0,0,1,16.65-8.8,66,66,0,0,1,21.22-3.3q17.12,0,27.72,8.75t10.61,25v48.47c0,1.3,0,2.57,0,3.81s.11,2.48.24,3.72l7.44,1v11.72Zm-29.5-13.4a32.57,32.57,0,0,0,16.66-4.37q7.53-4.37,10.79-10.51V360.09H912.52q-11.25,0-17.82,5.21T888.15,378q0,6.6,4.09,10.09T904.61,391.53Z"
      transform="translate(0 0)"
    />
    <path
      className="gray"
      d="M970.67,393.21l15.17-2.61V318.68l-15.17-2.6V304.26h31.54l1.3,15a36.59,36.59,0,0,1,12.61-12.42,33.69,33.69,0,0,1,17.26-4.42q16.27,0,25.21,9.54t8.93,29.44V390.6l15.17,2.61v11.72H1034V393.21l15.16-2.61V341.76q0-13.31-5.25-18.89t-16-5.58a25.85,25.85,0,0,0-14,3.81,27.9,27.9,0,0,0-9.73,10.42V390.6l15.17,2.61v11.72H970.67Z"
      transform="translate(0 0)"
    />
    <path
      className="gray"
      d="M1140.28,392.56a24.75,24.75,0,0,0,16-5.63q6.93-5.62,6.93-13.82h16.46l.19.56q.46,13-11.58,23.12a42.38,42.38,0,0,1-28,10.09q-22,0-33.54-14.32t-11.59-36v-3.91q0-21.5,11.63-35.86t33.5-14.38a56.28,56.28,0,0,1,21.72,4,44,44,0,0,1,16,11l.83,22.33h-14.6l-4-15.82a24.46,24.46,0,0,0-8.38-5.11,31.94,31.94,0,0,0-11.53-2q-14.43,0-20.61,10.38t-6.19,25.44v3.91q0,15.45,6.14,25.73T1140.28,392.56Z"
      transform="translate(0 0)"
    />
    <path
      className="gray"
      d="M1243.73,406.88q-21,0-33.44-14t-12.42-36.33v-4.09q0-21.49,12.79-35.78t30.28-14.28q20.28,0,30.75,12.28t10.47,32.75v11.45h-65.31l-.28.46q.27,14.52,7.35,23.87t19.81,9.35a46,46,0,0,0,16.33-2.66,37.42,37.42,0,0,0,12.14-7.3l7.17,11.91a44.11,44.11,0,0,1-14.28,8.79Q1256.2,406.89,1243.73,406.88Zm-2.79-90.06q-9.21,0-15.72,7.77t-8,19.49l.18.47h46.43v-2.42q0-10.8-5.77-18.05T1240.94,316.82Z"
      transform="translate(0 0)"
    />
  </svg>
);

TypTapLogo.defaultProps = {
  className: 'typtap-logo'
};

export default TypTapLogo;
