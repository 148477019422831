import React from 'react';

const EmailIcon = () => (
  <svg id="Email_Layer_1" viewBox="0 0 42 33">
    <path
      className="fill white"
      d="M24,29a3.87,3.87,0,0,1-1.6-.45,14.93,14.93,0,0,1-1.52-.82q-.57-.37-1.68-1.23A8,8,0,0,0,18,25.64Q8,18.34,3.74,15.05A1.87,1.87,0,0,1,3,13.5V11.9a3.69,3.69,0,0,1,.66-2.18A3.89,3.89,0,0,1,6.94,8H41.06a3.89,3.89,0,0,1,3.27,1.71A3.62,3.62,0,0,1,45,11.9v1.6a1.87,1.87,0,0,1-.74,1.55q-4,3.12-14.27,10.59a8,8,0,0,0-1.19.86q-1.11.86-1.68,1.23a16.1,16.1,0,0,1-1.52.82A3.87,3.87,0,0,1,24,29Zm20.18-9.08a.4.4,0,0,1,.53-.08.53.53,0,0,1,.29.49V37.06a3.92,3.92,0,0,1-1.73,3.29A3.63,3.63,0,0,1,41.1,41H6.94a3.77,3.77,0,0,1-2.79-1.15A3.77,3.77,0,0,1,3,37.06V20.33a.44.44,0,0,1,.29-.45.53.53,0,0,1,.53,0Q6.52,22,16.45,29.19q.16.16,1.11.9c.63.49,1.14.86,1.52,1.11s.87.55,1.47.9a7.32,7.32,0,0,0,1.77.78,6.22,6.22,0,0,0,1.68.25,5.45,5.45,0,0,0,1.48-.21,11.36,11.36,0,0,0,1.39-.49,9.18,9.18,0,0,0,1.4-.78c.49-.33.9-.62,1.23-.86l1.14-.86a11.44,11.44,0,0,0,.91-.74Q41.23,22.21,44.18,19.92Z"
      transform="translate(-3 -8)"
    />
  </svg>
);

export default EmailIcon;
