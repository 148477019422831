import { useEffect } from 'react';
import PropTypes from 'prop-types';

// Handle anchor tags if present in URL, if not, scroll to top
const ScrollHandler = ({ containerId, headerId, location, children }) => {
  useEffect(() => {
    const scrollableContainer = document.getElementById(containerId);
    const headerHeight = document.getElementById(headerId).clientHeight;
    const element = document.getElementById(location.hash.replace('#', ''));

    setTimeout(() => {
      scrollableContainer.scrollTo({
        behavior: element ? 'smooth' : 'auto',
        top: element ? element.offsetTop - headerHeight : 0
      });
    }, 100);
  }, [location, containerId, headerId]);

  return children;
};

ScrollHandler.propTypes = {
  children: PropTypes.node.isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string,
    search: PropTypes.string,
    hash: PropTypes.string,
    state: PropTypes.any,
    key: PropTypes.string
  }).isRequired,
  containerId: PropTypes.string.isRequired
};

export default ScrollHandler;
