import React from 'react';
import { Link } from 'gatsby';
import Helmet from 'react-helmet';

import YouTubeIcon from '../assets/svgs/YouTubeIcon';
import LinkedInIcon from '../assets/svgs/LinkedInIcon';
import FacebookIcon from '../assets/svgs/FacebookIcon';
import InstagramIcon from '../assets/svgs/InstagramIcon';
import TelephoneIcon from '../assets/svgs/TelephoneIcon';
import FaxIcon from '../assets/svgs/FaxIcon';
import EmailIcon from '../assets/svgs/EmailIcon';

import image from '../images/Powered-by-Exzeo-reverse.png';

import CarouselFooter from './carouselFooter';
import { ROUTES } from '../constants/navigation';

const Footer = ({ location }) => (
  <React.Fragment>
    <section className="content colorized carousel-wrapper">
      <CarouselFooter location={location} />
    </section>
    <footer>
      <section>
        <nav className="sitemap" title="sitemap">
          <h5 className="widget-title"> Site Map </h5>
          <ul id="menu-footer-menu" className="menu">
            <li className="menu-item">
              <Link to={ROUTES.home.path}>Home</Link>
            </li>
            <li className="menu-item">
              <Link to={ROUTES.blog.path}>About Us</Link>
            </li>
            {/* <li className="menu-item">
              <Link to={`${ROUTES.leadership.path}`}>Leadership Team</Link>
            </li> */}
            <li className="menu-item menu-item-home menu-item-has-children">
              <Link to={ROUTES.products.path}>Products</Link>
            </li>
            <li className="menu-item menu-item-has-children">
              <Link to={ROUTES.claims.path}>Claims</Link>
            </li>
            <li className="menu-item">
              <Link to={ROUTES.contact.path}>Contact</Link>
            </li>
            <li className="menu-item">
              <Link to={ROUTES.careers.path}>Careers</Link>
            </li>
            <li className="menu-item">
              <Link to={ROUTES.pressKit.path}>Media Assets</Link>
            </li>
          </ul>
        </nav>
        <article>
          <nav className="legal" title="legal">
            <h5>Legal</h5>
            <ul className="textwidget custom-html-widget">
              <li>
                <Link to={`${ROUTES.service.path}#top`}>Terms of Service</Link>
              </li>
              <li>
                <Link to={`${ROUTES.privacy.path}#top`}>Privacy Notice</Link>
              </li>
              <li>
                <Link to={`${ROUTES.accessibility.path}#top`}>
                  Accessibility
                </Link>
              </li>
            </ul>
          </nav>
          <div className="contact-links">
            <h5>Contact Info</h5>
            <ul>
              <li>
                <a href="tel:8442897968" title="telephone number">
                  <TelephoneIcon />
                  &nbsp;Tel: 844-289-7968
                </a>
              </li>
              <li>
                <a
                  href={`mailto:${process.env.GATSBY_CUSTOMER_SERVICE_EMAIL}`}
                  title="email address"
                >
                  <EmailIcon />
                  &nbsp;Email: {process.env.GATSBY_CUSTOMER_SERVICE_EMAIL}
                </a>
              </li>
              <li>
                <a href="tel:3525334073" title="fax number">
                  <FaxIcon />
                  &nbsp;Fax: 352-533-4073
                </a>
              </li>
            </ul>
          </div>
          <div className="social-media-wrapper">
            <div className="social-media">
              <a
                title="YouTube"
                href="https://www.youtube.com/channel/UCSGZAcL3freC7TCsJP48cxQ/featured?view_as=subscriber"
                target="_blank"
                rel="noopener noreferrer"
                className="youtube"
              >
                <YouTubeIcon />
              </a>
            </div>
            <div className="social-media">
              <a
                title="LinkedIn"
                href="https://www.linkedin.com/company/typtap/"
                target="_blank"
                rel="noopener noreferrer"
                className="linkedin"
              >
                <LinkedInIcon />
              </a>
            </div>
            <div className="social-media">
              <a
                title="Facebook"
                href="https://www.facebook.com/TypTap-850156878463565"
                target="_blank"
                rel="noopener noreferrer"
                className="facebook"
              >
                <FacebookIcon />
              </a>
            </div>
            <div className="social-media">
              <a
                title="Instagram"
                href="https://www.instagram.com/typtapinsurance/"
                target="_blank"
                rel="noopener noreferrer"
                className="instagram"
              >
                <InstagramIcon />
              </a>
            </div>

            <div className="powered-by-exzeo">
              <a
                title="Exzeo"
                href="https://exzeo.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  alt="Exzeo – Modernizing Insurance"
                  src={image}
                  border="0"
                />
              </a>
            </div>
          </div>
          <div className="underwriting-demotech-wrapper">
            <p>
              TypTap&apos;s Homeowners insurance products and services are
              underwritten by TypTap Insurance Company in all active states. In
              Arkansas, California, Maryland, New Jersey, Ohio, Pennsylvania,
              South Carolina and Texas TypTap&apos;s Flood insurance products
              and services are underwritten by Homeowners Choice Property &amp;
              Casualty Insurance Company, Inc.
            </p>
            <div id="dc-seal"></div>
          </div>
          <div className="disclaimer-wrapper">
            <p className="disclaimer">
              &copy; {new Date().getFullYear()} TypTap Insurance Inc, all rights
              reserved. TypTap Insurance&copy; v.{process.env.GATSBY_VERSION}
            </p>
          </div>
        </article>
      </section>
    </footer>
    <Helmet>
      <script
        id="dc-seal-embed"
        src="https://demotech.com/wp-content/plugins/demotech-companies/assets/js/dc-embed-seal.js"
        data-naic="15885"
        data-size="auto"
        async
      ></script>
    </Helmet>
  </React.Fragment>
);

export default Footer;
